<template>
  <div>
    <el-upload
      :action="this.$store.getters.imgUrl"
      list-type="picture-card"
      :headers="header"
      :file-list="list"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :data="action"
      :on-success="handleAvatarSuccess"
      :limit="limit"
      :on-exceed="exceed"
    >
      <i class="el-icon-plus" />
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    limit:{
        type:[String,Number],
        default:3
    },
    action: {
      type: Object
    }
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain
    }
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    handleRemove(file, fileList) {
      // var arr = this.form.productImages.filter(item => item.url != file.url)
      // this.form.productImages = fileList
      this.$emit('update:list', fileList)
      this.$emit('changes', fileList)
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        // console.log(res)
        var arr = this.list
        arr.push({
          name: file.name,
          url: this.baseurl + this.yu + res.data.url
        })
        this.$emit('update:list', arr)
        this.$emit('changes', arr)
      } else {
        this.$emit('update:list', [...this.list])
        this.$emit('changes', [...this.list])
        this.$message.error(res.message)
      }
      // this.form.banner = URL.createObjectURL(file.raw)
    },
    exceed() {
      this.$message.warning('最多传三张图片')
    }
  }
}
</script>

<style lang="less" scoped></style>
