<template>
  <div class="en_add">
    <!-- <el-card style="margin-bottom: 10px;">
      <el-page-header style="margin-bottom: 30px;" @back="goBack" />
    </el-card> -->
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="rule"
        label-width="auto"
        :inline="false"
        size="normal"
      >
        <el-form-item label="logo">
          <!-- <el-col :span="8"> -->
          <up-img :banner.sync="form.logo" :action="action"></up-img>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item label="产品名称">
          <el-col :span="8">
            <el-input v-model="form.name" />
          </el-col>
        </el-form-item>
        <el-form-item label="轮换图片">
          <!-- <el-col :span="8"> -->
          <imgups :list.sync="form.screenList" :action="action"></imgups>
          <!-- </el-col> -->
        </el-form-item>
        <template>
          <el-form-item>
            <el-button type="primary" size="medium" @click="onSubmit">{{
              id ? '保存' : '提交'
            }}</el-button>
          </el-form-item>
        </template>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import imgup from '../../../components/upImg/index.vue'
import rules from '../../../utils/rules'
import imgups from '../../../components/uplist/index.vue'
export default {
  components: {
    imgup,
    imgups
  },
  data() {
    return {
      id: null,
      title: '加载中...',
      keyword: '',
      form: {
        logo: '',
        screenList: []
      },

      rule: {
        name: [rules.req('请输入企业简称')],
        fullName: [rules.req('请输入企业全称')],
        logo: [rules.req('请输入企业logo')],
        city: [rules.req('请输入省份和城市')],
        people: [rules.req('请输入公司人事')],
        financing: [rules.req('请输入融资情况')],
        type: [rules.req('请输入类型')],
        business: [rules.req('请输入行业')],
        serverArea: [rules.req('请输入业务区域')],
        companyDesc: [rules.req('请输入企业介绍')],
        intro: [rules.req('请输入企业简介')]
      },
      action: {
        action: 'enterprise'
      },
      province: {},
      city: {},
      typeList: [],
      businessList: [],
      subBusinessList: [],
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain
    }
  },
  watch: {},
  computed: {},
  created() {
    this.getform()
  },
  methods: {
    goBack() {
      history.go(-1)
    },
   
    searchFirms() {},
    submit(row) {
      this.form = row
    },
    async onSubmit() {
      //   console.log(this.form)
      var url = this.form.screenList.map(item => item.url)
      //   return
      if (this.id) {
        var { data: res } = await this.$http.post(
          '/admin/EnterpriseProduct/edit',
          {
            ...this.form,
            screenList: url.toString()
          }
        )
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          history.back()
        }
      } else {
      }
    },

    async getform() {
      if (this.$route.query.id) {
        var id = this.$route.query.id
        this.id = id
        this.$http
          .get('/admin/EnterpriseProduct/getById?id=' + id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              //   console.log(res)
              this.form = res.data
              this.form.id = id * 1
              this.form.screenList = res.data.screenList
                ? res.data.screenList.split(',').map(item => {
                    return {
                      url: item,
                      name: item
                    }
                  })
                : []
              //   this.title = res.data.enterprise.fullName
              //  this.form.initViews =
              //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
            }
          })
      }
    }
  }
}
</script>

<style lang="less">
.en_add {
  .box {
    display: inline-block;
    width: 300px;
    input {
      border-color: #dcdfe6 !important;
    }
  }
}
</style>
